<template>
  <div class="fourth container" :class="{ 'close-anim': closeAnim }">
    <RFModal v-if="isOpen" @close="close" class="fourth-modal">
      <div v-show="isLive">
        <Tabs :tabs="tabs" @select="changeCurrentTab" />
      </div>
      <iframe
        v-if="isLive && currentTab === 0"
        src="https://ipeye.ru/ipeye_service/api/iframe.php?iframe_player=1&dev=e1cbd1ed0996418fbb939c58e1a84d9a&autoplay=0&archive=1"
        class="fourth-modal-frame"
        frameBorder="0"
        seamless="seamless"
        allowfullscreen
        >Ваш браузер не поддерживает фреймы!</iframe
      >
      <iframe
        v-else-if="isLive && currentTab === 1"
        src="https://ipeye.ru/ipeye_service/api/iframe.php?iframe_player=1&dev=6ee7d6ebc1fc402eacdbb1d319a4472a&autoplay=0&archive=1"
        class="fourth-modal-frame"
        frameBorder="0"
        seamless="seamless"
        allowfullscreen
        >Ваш браузер не поддерживает фреймы!</iframe
      >
      <iframe
        v-else
        src="https://nur-sultan3d.kz/3d/orda-invest/shymkent/#pano547397/65.1/26.3/43.6"
        class="fourth-modal-frame"
        frameBorder="0"
        seamless="seamless"
        allowfullscreen
        >Ваш браузер не поддерживает фреймы!</iframe
      >
    </RFModal>
    <div class="fourth-inner">
      <section class="fourth-left wow" data-wow-offset="250">
        <h2 class="fourth-left__title">
          Обзор территории
        </h2>
        <p class="fourth-left__text--strong">
          Мы всегда стараемся создавать самые комфортные условия для наших
          клиентов
        </p>
        <p class="fourth-left__text">
          На территории жилого комплекса размещено все самое необходимое для
          полноценной жизни: минимаркеты, аптека, салон красоты, образовательный
          центр, кофейня и ресторан. Для безопасности периметр двора частично
          огражден и находится под постоянным видеонаблюдением, которое
          отслеживается охраной комплекса
        </p>
        <button class="fourth-left__live-btn" @click="openModal">
          <Icon icon-name="live" class="fourth-left__live-btn--pulse" /> LIVE
          трансляция
        </button>
      </section>
      <div
        class="fourth-right wow"
        data-wow-offset="250"
        @click="openModal(360)"
      >
        <img
          src="@/assets/images/fourth/fourth-main.webp"
          class="fourth-right__img"
          alt="Фото территории"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icons/index.vue'
import Tabs from '@/components/molecules/Tabs/index.vue'
import RFModal from '@/components/atoms/Modals/RFModal.vue'
import { WOW } from 'wowjs'
export default {
  name: 'FourthSection',
  components: { Icon, RFModal, Tabs },
  data() {
    return {
      isOpen: false,
      isLive: false,
      closeAnim: false,
      currentTab: 0,
      tabs: [
        {
          label: 'Камера 1',
        },
        {
          label: 'Камера 2',
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  watch: {
    isOpen: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
  },
  methods: {
    openModal(val) {
      val === 360 ? (this.isLive = false) : (this.isLive = true)
      this.isOpen = true
    },
    close() {
      this.closeAnim = true
      setTimeout(() => {
        this.isOpen = !this.isOpen
        this.closeAnim = false
      }, 500)
    },
    changeCurrentTab(val) {
      this.currentTab = val
    },
  },
}
</script>

<style lang="scss" scoped>
.fourth {
  width: 100%;
  position: relative;
  height: fit-content;

  &-inner {
    gap: 50px;
    justify-content: space-between;
    @include flex-row();

    @include tablet-portrait {
      gap: 30px;
      flex-direction: column;
    }
    @include mobile {
      gap: 16px;
    }
  }

  &-left {
    align-items: flex-start;
    justify-content: center;
    max-width: 600px;
    animation: manifestation1 0.8s ease-in;
    @include flex-column();

    @include tablet-portrait {
      max-width: 500px;
    }
    @include mobile-landscape {
      max-width: 80%;
      order: 2;
    }
    @include mobile {
      max-width: none;
      width: 100%;
    }
    &__title {
      padding-bottom: 30px;
      @include font-styles(48px, 58px, 700, $c-dark, 0, $font-third);

      @include mobile {
        padding: 16px 0;
        @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
      }
    }
    &__text {
      padding-bottom: 22px;
      max-width: 92%;
      text-align: left;
      @include font-styles(18px, 24px, 400, $c-gray);

      @include mobile {
        max-width: none;
        @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
      }
    }
    &__text--strong {
      max-width: 580px;
      padding-bottom: 30px;
      @include font-styles(22px, 30px, 700, $c-dark);

      @include mobile {
        padding-bottom: 14px;
        @include font-styles(16px, 20px, 700, $c-dark);
      }
    }
    &__live-btn {
      width: 250px;
      cursor: pointer;
      height: 60px;
      border-radius: 10px;
      border: 1px solid $c-main-green;
      background: inherit;
      gap: 10px;
      justify-content: center;
      align-items: center;
      @include flex-row();
      @include font-styles(20px, 22px, 600, $c-main-green);

      @include mobile {
        width: 100%;
      }

      &--pulse {
        animation: puls 1.5s infinite;

        @keyframes puls {
          0% {
            opacity: 1;
          }
          25% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.01);
      }
    }
  }
  &-right {
    position: relative;
    animation: manifestationOpacity 0.8s linear;
    &__img {
      border-radius: 80px;
      height: 590px;
      min-width: 590px;
      cursor: pointer;

      @include mobile-landscape {
        width: 100%;
        min-width: auto;
      }
      @include mobile {
        width: 100%;
        border-radius: 80px 20px 20px 20px;
        height: 340px;
      }
    }
    &::before {
      content: '';
      width: 200px;
      height: 200px;
      position: absolute;
      top: calc(50% - 100px);
      left: calc(50% - 100px);
      background: url('../../../../assets/images/fourth/360.svg') center center /
        cover no-repeat;

      @include tablet-portrait {
        width: 150px;
        height: 150px;
        top: calc(295px - 75px);
        left: calc(295px - 75px);
      }
      @include mobile-landscape {
        top: calc(50% - 75px);
        left: calc(50% - 75px);
      }
    }
  }

  &-modal-frame {
    max-width: 940px;
    width: 100%;
    max-height: 510px;
    height: 100%;

    @include tablet-portrait {
      max-width: auto;
    }
  }
}

::v-deep .modal {
  &__body {
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include flex-column();
  }
}

::v-deep .tab__item + .tab__item {
  margin-left: 10px;
}
</style>
