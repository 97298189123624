<template>
  <ul class="tab">
    <li class="tab__item" v-for="(tab, i) in tabs" :key="i">
      <RFButton
        type="tab"
        class="tab"
        :class="{ 'tab--active': activeTab === i }"
        @click="select(i)"
      >
        {{ tab.label }}
      </RFButton>
    </li>
  </ul>
</template>

<script>
import RFButton from '@/components/atoms/Buttons/index.vue'

export default {
  name: 'RFTabs',
  components: { RFButton },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  methods: {
    select(i) {
      this.activeTab = i
      this.$emit('select', i)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__item {
    list-style-type: none;
  }
}
</style>
